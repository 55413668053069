import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import './App.css';

const theme = responsiveFontSizes(createTheme({
}));

function App() {
  const headerStyle = {
    borderBottom: '1px solid grey',
    backgroundColor: 'rgba(255, 255, 255, 0.98)'
  }

  return (
    <ThemeProvider theme={theme}>
      <AppBar style={headerStyle} elevation={0}>
        <Toolbar variant="dense">
          <Link href={process.env.REACT_APP_BASE_URL}>
            <Typography variant="h5" color='primary' noWrap>Dustclick</Typography>
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box style={{height: 400, paddingTop: '15%', textAlign: 'center'}}>
        <Typography
          variant='h2'
          color='textPrimary'
        >Make noise to let the world <strong style={{backgroundColor: 'yellow'}}>non boring</strong>.</Typography>
      </Box>
      <Divider variant="middle" style={{marginTop: 64}} />
      <Box>
        <Typography variant="body2" align="center" style={{marginTop: 12}}>
          {'© '}
          <Link color="inherit" href={process.env.REACT_APP_BASE_URL}>dustclick</Link>
          {` `}{new Date().getFullYear()}{'.'}
        </Typography>
      </Box>
    </ThemeProvider>
  );
}

export default App;
